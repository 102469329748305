import { httpClient } from '../http/client';
import {
  AcceptTermsAndConditionsRequestBody,
  AcceptTermsAndConditionsResponseBody,
} from '@repo/bff';

export const acceptTermsAndConditions = async ({
  body,
  cookie,
}: {
  cookie?: string;
  body: AcceptTermsAndConditionsRequestBody;
}): Promise<AcceptTermsAndConditionsResponseBody> =>
  httpClient
    .headers(
      cookie
        ? {
            cookie,
          }
        : {},
    )
    .put(body, '/v1/user/terms-and-conditions')
    .json();
