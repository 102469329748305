'use client';

import { AuthInfoResponseBody } from '@repo/bff';
import { useQuery } from '@tanstack/react-query';
import { createContext, useContext, useEffect } from 'react';
import { fetchUser } from '../lib/fetchUser';
import { usePathname, useRouter } from 'next/navigation';
import { useLanguage, i18n } from '@repo/i18n';

export const USER_QUERY_KEY = 'user';

export interface AuthContext {
  user: AuthInfoResponseBody;
}

const AuthContext = createContext<AuthContext | undefined>(undefined);

export const useAuth = () => useContext(AuthContext)!;

export const usePartialAuth = () => useContext(AuthContext);

export const AuthProvider = ({
  children,
  user,
}: {
  children: React.ReactNode;
  user: AuthInfoResponseBody;
}) => {
  const pathName = usePathname();

  const { lang } = useLanguage();

  const router = useRouter();

  useEffect(() => {
    if (i18n.locales.some((l) => pathName === `/${l}`)) {
      if (user.user_type === 'cannabis_consumer') {
        return router.push(`/${lang}/user/profile`);
      }

      if (user.user_type === 'doctor') {
        return router.push(`/${lang}/patients`);
      }
    }
  }, [pathName]);

  const { data } = useQuery({
    queryFn: () => fetchUser(),
    queryKey: [USER_QUERY_KEY],
    initialData: user,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  });

  return (
    <AuthContext.Provider
      value={{
        user: data,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
