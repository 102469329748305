'use client';

import { TermsAndConditionsInfoResponseBody, UserModel } from '@repo/bff';
import { useQuery } from '@tanstack/react-query';
import { createContext, useContext } from 'react';
import { fetchTermsAndConditions } from '../lib/fetchTermsAndConditions';

export const termsAndConditionsQueryKey = (user: Pick<UserModel, 'id'>) =>
  `terms-and-conditions-${user.id}`;

export interface TermsAndConditionsContext {
  data: TermsAndConditionsInfoResponseBody | undefined;
}

const TermsAndConditionsContext = createContext<
  TermsAndConditionsContext | undefined
>(undefined);

export const useTermsAndConditions = () =>
  useContext(TermsAndConditionsContext)!;

export const usePartialTermsAndConditions = () =>
  useContext(TermsAndConditionsContext);

export const TermsAndConditionsProvider = ({
  children,
  user,
}: {
  children: React.ReactNode;
  user: Pick<UserModel, 'id'>;
}) => {
  const { data } = useQuery({
    queryFn: () => fetchTermsAndConditions(),
    queryKey: [termsAndConditionsQueryKey(user)],
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  });

  return (
    <TermsAndConditionsContext.Provider
      value={{
        data,
      }}
    >
      {children}
    </TermsAndConditionsContext.Provider>
  );
};
