import { httpClient } from '../http/client';
import { TermsAndConditionsInfoResponseBody } from '@repo/bff';

export const fetchTermsAndConditions = async (
  cookie?: string,
): Promise<TermsAndConditionsInfoResponseBody> =>
  httpClient
    .headers(
      cookie
        ? {
            cookie,
          }
        : {},
    )
    .get('/v1/user/terms-and-conditions')
    .json();
