import { useLanguage } from '@repo/i18n';
import { useToast } from '../../../packages/ui/src/components/ui/use-toast';
import { WretchError } from 'wretch/resolver';

export const useApiError = () => {
  const { toast } = useToast();

  const { dictionary } = useLanguage();

  const handleError = (error: WretchError) => {
    const code = error.json?.code || 'unexpected_error';

    toast({
      title:
        dictionary.errors[code as keyof typeof dictionary.errors]?.title ||
        error.json.code ||
        'Unexpected error',
      description:
        dictionary.errors[code as keyof typeof dictionary.errors]?.message,
      variant: 'destructive',
    });
  };

  return {
    handleError,
  };
};
