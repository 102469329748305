'use client';

import React, { useCallback, useState } from 'react';
import { useLanguage } from '@repo/i18n';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@ui/components/ui/dialog';
import {
  termsAndConditionsQueryKey,
  useTermsAndConditions,
} from '../context/TermsAndConditionsContext';
import { Checkbox } from '@repo/ui/components/ui/checkbox';
import { ScrollArea } from '@repo/ui/components/ui/scroll-area';
import { Spinner } from '@repo/ui/components/ui/Spinner';
import { acceptTermsAndConditions } from '../lib/acceptTermsAndConditions';
import { useApiError } from '../hooks/useApiError';
import { UserModel } from '@repo/bff';
import { useQueryClient } from '@tanstack/react-query';
import { useToast } from '../../../packages/ui/src/components/ui/use-toast';

export const TermsAndConditions = ({
  user,
  onLatestVersionAccepted,
}: {
  user: Pick<UserModel, 'id'>;
  onLatestVersionAccepted: 'skip' | 'show';
}) => {
  const { dictionary } = useLanguage();

  const [isOpen, setIsOpen] = useState(true);

  const [isLoading, setIsLoading] = useState(false);

  const { data } = useTermsAndConditions();

  const { handleError } = useApiError();

  const latestVersion = data?.latest_version;

  const queryClient = useQueryClient();

  const { toast } = useToast();

  const terms =
    dictionary.terms_and_conditions[
      `version_${latestVersion}` as keyof typeof dictionary.terms_and_conditions
    ];

  const handleAccept = useCallback(async () => {
    setIsLoading(true);
    try {
      await acceptTermsAndConditions({
        body: {
          version_id: latestVersion!,
        },
      });
      queryClient.invalidateQueries({
        queryKey: [termsAndConditionsQueryKey(user)],
      });
      toast({
        title: dictionary.terms_and_conditions.accepted,
        variant: 'default',
      });
    } catch (error: any) {
      console.log('error when try accept terms', error);
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  }, [latestVersion, handleError]);

  if (!latestVersion) return null;

  if (data?.is_latest_version_accepted && onLatestVersionAccepted === 'skip')
    return null;

  console.log('terms', terms);

  return (
    <div className=''>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className='max-w-screen-sm max-h-screen sm:max-w-[100px] lg:max-w-[800px]'>
          <DialogHeader>
            <DialogTitle>{dictionary.terms_and_conditions.title}</DialogTitle>
            <DialogDescription className='text-md'>
              {dictionary.terms_and_conditions.message}
            </DialogDescription>
          </DialogHeader>
          {!!isLoading && (
            <div className='w-full flex flex-row justify-center'>
              <Spinner size={'lg'} />
            </div>
          )}
          {!isLoading && (
            <div className='grid gap-4 py-1'>
              {
                <ScrollArea className='max-h-72 md:max-h-96 rounded-md border p-2'>
                  {Object.keys(terms).map((key) => {
                    const value = terms[key as keyof typeof terms];

                    if (key.includes('title')) {
                      return <DialogTitle key={key}>{value}</DialogTitle>;
                    }
                    if (key.includes('content')) {
                      return (
                        <DialogDescription
                          className='font-light text-justify md:px-2 px-1'
                          key={key}
                        >
                          {value}
                        </DialogDescription>
                      );
                    }
                  })}
                </ScrollArea>
              }
            </div>
          )}
          <DialogFooter>
            <div className='flex items-center space-x-2'>
              <Checkbox
                id='terms'
                onClick={() => handleAccept()}
                checked={data?.is_latest_version_accepted}
                disabled={isLoading || data?.is_latest_version_accepted}
              />
              <label
                htmlFor='terms'
                className='text-lg font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
              >
                {dictionary.terms_and_conditions.accept}
              </label>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};
